import "./Analyse.css";
import "./Common.css";
import plussIcon from "./plussIcon.svg";
import { Link } from "@reach/router";
import { useState } from "react";

export interface tagsProps {
  funnels: string[];
  dataChanger: (val: string) => void;
}

const FunnelTable = ({ funnels, dataChanger }: tagsProps) => {
  const [isClicked, setIsClicked] = useState(funnels[0]);

  const handleClick = (funnel: string) => {
    dataChanger(funnel);

    setIsClicked(funnel);
  };
  return (
    <div className="FT__buttContaner">
      {funnels.map((funnel, index) => (
        <button
          key={funnel}
          className={isClicked === funnel ? "FT__buttonSelected" : "FT__button"}
          onClick={() => handleClick(funnel)}
        >
          {funnel}
        </button>
      ))}

      <Link to={"/funnel"} className="FT__newFunnel">
        <div className="buttonDiv">
          <img src={plussIcon} alt="" className="buttonIcon" />
          <div className="buttonText">New funnel</div>
        </div>
      </Link>
    </div>
  );
};

export default FunnelTable;

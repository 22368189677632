import { RouteComponentProps, Link } from "@reach/router";
import { FC } from "react";
import "./Button.css";
import "./Home.css";
import NavHeader from "./NavHeader";
import forsideLogo from "./forsideLogo.png";

const Home: FC<RouteComponentProps> = () => {
  return (
    <div>
      <NavHeader />

      <img src={forsideLogo} alt="" className="forsideLogo" />

      <div className="infoContainer">
        <div className="info">
          <div className="infoHeading">Respect privacy</div>
          <div className="infoBody">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </div>
          <Link to={"/info"}>
            <div className="learnMore">Learn more...</div>
          </Link>
        </div>

        <div className="info">
          <div className="infoHeading">Funnel statistics</div>
          <div className="infoBody">
            Reprehenderit in voluptate velit esse cillum dolore eu fugiat null
            pariatur. Excepteur sint occaecat medenit cupidatat non proident,
            sunt in culpa qui officia deserunt mollit anim id est laborum.
          </div>
          <Link to={"/info"}>
            <div className="learnMore">Learn more...</div>
          </Link>
        </div>

        <div className="info">
          <div className="infoHeading">How it works</div>
          <div className="infoBody">
            Labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate velit
            esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
            cupidatat non pro.
          </div>
          <Link to={"/info"}>
            <div className="learnMore">Learn more...</div>
          </Link>
        </div>
      </div>

      <div className="getStarted">
        <Link className="submit" to={"/add_website"}>
          Get started
        </Link>
      </div>
    </div>
  );
};

export default Home;

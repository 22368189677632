
// OBS OBS! Endringer her må gjentas i AddWebsite.tsx

import { RouteComponentProps , Link } from "@reach/router";
import { FC } from "react";
import "./AddWebsite.css";
import "./Common.css";
import NavHeader from "./NavHeader";
import ImplementCode from "./ImplementCode";

const AddWebsiteSuccess: FC<RouteComponentProps> = () => {

  
    return (
      <div>
        <NavHeader/>
        <ImplementCode/>

        <div className="addWebContainer">
          <h3>Add website</h3>

          <h4>Your website was successfully added!</h4>


          <Link to={"/graph"}>
              <button className="transpBack">
                <div className="submit">Go to analysis</div>
              </button>
            </Link>
        </div>
        
      

  
        
      </div>
    );
  };
  
  export default AddWebsiteSuccess;
  
import axios from "axios";
import { getBackendPath } from "./paths";

const hostname = getBackendPath();

export async function postPage(
  domain: string,
  currentSubPage: string,
  adTag: string,
  referrer: string
): Promise<number> {
  const result = await axios.post(hostname + "/events", {
    url: currentSubPage,
    referrer: referrer,
    source: adTag,
    domain: domain,
  });
  if (result.status !== 200) return 0;
  else {
    return result.data;
  }
}

export async function postFunnel(urls: string[], domain: string, name: string) {
  return axios.post(hostname + "/funnel", {
    name: name,
    domain: domain,
    urls: urls,
  });
}

type TagData = {
  id: number;
  url: string;
  ad_tag: string;
  count: number;
};

export async function getAdURLs(adTag: string): Promise<[TagData]> {
  console.log("resuølt");

  const result = await axios.post(hostname + "/get_source_urls", {
    domain: "localhost:3000",
    source: adTag,
  });
  console.log("Resylt data ", result.data);
  if (result.status !== 200) {
    return [{ id: -1, url: "", ad_tag: "", count: -1 }];
  } else {
    return result.data;
  }
}

export async function getAllSources(domain: string) {
  const result = await axios.post(hostname + "/get_all_sources", {
    domain: domain,
  });

  return result.data;
}

export async function getFunnel(funnel: string, domain: string) {
  const result = await axios.post(hostname + "/get_funnel", {
    name: funnel,
    domain: domain,
  });

  return result.data;
}

export async function getPageCount(
  url: string,
  source: string,
  domain: string
) {
  if (source === "total") {
    source = "";
  }
  const result = await axios.post(hostname + "/get_page_count", {
    domain: domain,
    url: url,
    source: source,
  });

  if (result.data.length === 0) {
    return [{ count: 0 }];
  } else {
    return result.data;
  }
}

export async function getAllFunnels(domain: string) {
  console.log("result");

  const result = await axios.post(hostname + "/get_all_funnels", {
    domain: domain,
  });

  return result.data;
}

export async function getAllDomains(): Promise<DomainProps[]> {
  const result = await axios.get(hostname + "/get_domains");
  return result.data;
}

interface DomainProps {
  domain: string;
}
export async function postDomain(domain: string) {
  return axios.post(hostname + "/users", { domain: domain });
}

export async function postSource(domain: string, source: string) {
  const result = await axios.post(hostname + "/sources", {
    domain: domain,
    source: source,
  });
  return result;
}

export async function getAllUrls(domain: string) {
  const result = await axios.post(hostname + "/get_all_urls", {
    domain: domain,
  });
  return result.data;
}
export async function deleteSource(domain: string, source: string) {
  const result = await axios.post(hostname + "/delete_source", {
    domain: domain,
    source: source,
  });
  return result;
}

export async function deletePage(domain: string, url: string) {
  const result = await axios.post(hostname + "/delete_page", {
    domain: domain,
    url: url,
  });
  return result;
}

export async function deleteFunnel(domain: string, name: string) {
  const result = await axios.post(hostname + "/delete_funnel", {
    domain: domain,
    name: name,
  });
  return result;
}

export async function deleteDomain(domain: string) {
  const result = await axios.post(hostname + "/delete_domain", {
    domain: domain,
  });
  return result;
}

export async function deleteAllInDomain(domain: string) {
  return axios.post(hostname + "/delete_all_in_domain", {
    domain: domain,
  });
}

import { RouteComponentProps } from "@reach/router";
import { FC } from "react";
import "./Common.css";

const Info: FC<RouteComponentProps> = () => {

  
    return (
      <div>
        <h1>
          *En infoside
        </h1>
      </div>

    );
  };
  
  export default Info;
  
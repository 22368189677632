export const getBackendPath = () => {
  const hostname = window.location.hostname;

  if (hostname?.endsWith("test.iterate.no")) {
    return "https://analytics-backend.test.iterate.no";
  }

  if (hostname?.endsWith("localhost")) {
    return "http://localhost:3001";
  }

  return "https://analytics-backend.app.iterate.no";
};

export const getFrontendPath = () => {
  const hostname = window.location.hostname;

  if (hostname?.endsWith("test.iterate.no")) {
    return "https://analytics-frontend.test.iterate.no";
  }

  if (hostname?.endsWith("localhost")) {
    return "http://localhost:3000";
  }

  return "https://analytics-frontend.app.iterate.no";
};

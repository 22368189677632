import { Link, useNavigate } from "@reach/router";
import "./NavHeader.css";
import "./Common.css";
import noCookieNoCry from "./noCookieNoCry.png";
import { getAllDomains } from "./PassToAPI";
import { useState } from "react";
import { useEffect } from "react";
import { useDomainStore } from "./store/domain";

interface DomainProps {
  domain: string;
}

const NavHeader = () => {
  const [domains, setDomains] = useState<string[]>([]);
  const { domain, changeDomain } = useDomainStore();
  const navigate = useNavigate();

  async function getDomains() {
    let res: DomainProps[];
    res = await getAllDomains();

    let array: string[] = [];
    res.forEach((r) => {
      array.push(r.domain);
    });
    setDomains(array);
  }

  useEffect(() => {
    getDomains();
  }, []);

  function handleChange(chosenDomain: string) {
    changeDomain(chosenDomain);
    navigate("/graph");
    window.location.reload();
  }
  console.log(domain);
  return (
    <div className="navContainer">
      <Link to="/">
        <img src={noCookieNoCry} alt="" className="logo" />
      </Link>

      <form action="">
        <select
          className="website"
          name="websites"
          id="websites"
          onChange={(event) => handleChange(event.target.value)}
          value={domain}
        >
          {domains.map((d: any, index: any) => {
            return (
              <option key={d} value={d}>
                {" "}
                {d}
              </option>
            );
          })}
          <option value="" disabled>
            {" "}
            Choose website...
          </option>

          <option value="./addWebsite">+ Add website</option>
        </select>
      </form>
    </div>
  );
};
export default NavHeader;

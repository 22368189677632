import {
  deleteSource,
  deleteFunnel,
  deletePage,
  deleteDomain,
} from "./PassToAPI";
import { RouteComponentProps } from "@reach/router";
import { FC, useEffect, useState } from "react";

async function deleteSources() {
  const result = await deleteSource("http://localhost:3002/", "insta");
}
async function deleteFunnels() {
  const result = await deleteFunnel("http://localhost:3002/", "funnel");
}
async function deletePages() {
  const result = await deletePage(
    "http://localhost:3002/",
    "http://localhost:3002/new"
  );
}
async function deleteDomains() {
  const result = await deleteDomain("http://localhost:3002/");
}

const Delete: FC<RouteComponentProps> = () => {
  useEffect(() => {
    //deletePages();
    //deleteFunnels();
    //deleteSources();
    //deleteDomains();
  }, []);

  return <div>DELETE</div>;
};
export default Delete;

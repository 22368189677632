import { RouteComponentProps, Link } from "@reach/router";
import { FC, useState } from "react";
import "./funnel.css";
import "./Common.css";
import bossBigIcon from "./bossBigIcon.svg";
import plussIcon from "./plussIcon.svg";
import exitIcon from "./exitIcon.svg";
import { useDomainStore } from "./store/domain";
// import NavHeader from "./NavHeader";

import { postFunnel } from "./PassToAPI";

const Funnel: FC<RouteComponentProps> = () => {
  const [title, setTitle] = useState<string>("");
  const [inputList, setInputList] = useState([{ url: "" }]);
  const { domain, changeDomain } = useDomainStore();

  async function handleSubmit() {
    const funnelList: string[] = [];
    inputList.forEach((key) => {
      funnelList.push(key.url);
    });
    console.log("inputList", inputList[1]);
    await postFunnel(funnelList, domain, title);
  }

  const handleInputChange = (e: any, index: number) => {
    const { name, value } = e.target;
    const list: any[] = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index: any) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { url: "" }]);
  };

  return (
    <div>
      <div className="formContainer">
        {/* EXIT  */}
        <div className="exit zoom">
          <Link to={"/graph"}>
            <img src={exitIcon} alt="" />
          </Link>
        </div>

        {/* FORM */}
        <div>
          <form action="">
            {" "}
            {/* NB! action can ødelegge? */}
            <div className="funnelName">
              <Input
                classLabel="funnelNameLabel"
                classInput="funnelNameInput"
                title="Add the pages you wish to track"
                id="funnelinput"
                value={title}
                onChange={(value) => setTitle(value)}
              />
            </div>
            <div className="infoText">
              (Only include "/path" from "expample.com/path")
            </div>
            {/* STEPSSS */}
            <div>
              {inputList.map((x, i) => {
                return (
                  <div>
                    <div className="stepContCont">
                      <div className="stepContainer">
                        {/* STEP */}
                        {/* <input 
                          className="stepInput stepName"
                          name="url"
                          value={x.url}
                          onChange={(e) => handleInputChange(e, i)}
                        /> */}

                        {/* URL */}
                        <input
                          placeholder="/path"
                          className="stepInput stepUrl"
                          name="url"
                          value={x.url}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </div>

                      {/* REMOVE STEP BUTTON */}
                      <div>
                        {inputList.length !== 1 && (
                          <button
                            className="transpBack removeButton zoom"
                            onClick={() => handleRemoveClick(i)}
                          >
                            <img src={bossBigIcon} alt="" />
                          </button>
                        )}
                      </div>
                    </div>

                    {/* ADD STEP BUTTON */}
                    <div>
                      {inputList.length - 1 === i && (
                        <button
                          onClick={handleAddClick}
                          className="transpBack addStepButton"
                        >
                          <div className="buttonDiv">
                            <img
                              src={plussIcon}
                              alt=""
                              className="buttonIcon"
                            />
                            <div className="buttonText">Add step</div>
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            {/* SUBMIT */}
            <Link to={"/graph"}>
              <button
                onClick={() => handleSubmit()}
                className="transpBack submitPos"
              >
                <div className="submit">Save and close</div>
              </button>
            </Link>
          </form>

          {/* Kan dette slettes? */}
          {/* <div >
            <h2>{title}</h2>
          </div> */}
        </div>
        {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
      </div>
    </div>
  );
};

export default Funnel;

// FUNC: Input

const Input: FC<{
  title: string;
  value: string;
  id: string;
  classLabel: string;
  classInput: string;
  onChange: (value: string) => void;
}> = ({ classLabel, classInput, title, value, onChange }) => {
  return (
    <label className={classLabel}>
      {title}
      <input
        className={classInput}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        placeholder="name"
      />
    </label>
  );
};

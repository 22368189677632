import "./Analyse.css";
import { useEffect, useState } from "react";
import penIcon from "./penIcon.svg";
import dateIcon from "./dateIcon.svg";
import {
  getAllUrls,
  getFunnel,
  getPageCount,
  deleteAllInDomain,
} from "./PassToAPI";
import { useDomainStore } from "./store/domain";

interface BarProps {
  currentSource: string;
  currentFunnel: string;
}
interface FunnelProps {
  url: string;
  count: number;
}

const BarChart = ({ currentSource, currentFunnel }: BarProps) => {
  const [diff, setDiff] = useState(0);
  const [urlList, setUrlList] = useState<FunnelProps[]>([]);
  const [source, setSource] = useState("");
  const [funnel, setFunnel] = useState("");
  const [largest, setLargest] = useState<number>(100);
  const { domain, changeDomain } = useDomainStore();

  //get data for source and funnel
  async function getFunnelUrls() {
    let result;
    if (currentFunnel === "All sites") {
      result = await getAllUrls(domain);
    } else {
      result = await getFunnel(currentFunnel, domain);
    }
    let array: FunnelProps[];
    array = [];
    for (let r of result) {
      if (currentFunnel === "") {
        return;
      } else {
        const tempResult = await getPageCount(r.url, currentSource, domain);
        let obj = { url: r.url, count: tempResult[0].count };
        array.push(obj);
      }
    }
    setUrlList(array);
    if (array.length !== 0) {
      const maxnum = Math.max(0, ...array.map((a) => a.count));
      setLargest(maxnum);
      diffFunc(array);
    }
  }

  //register change in source or funnel
  async function setCurrent() {
    if (source !== currentSource) {
      setSource(currentSource);
      getFunnelUrls();
    }
    if (funnel !== currentFunnel) {
      setFunnel(currentFunnel);
      getFunnelUrls();
    }
  }
  //run always
  setCurrent();
  useEffect(() => {
    getFunnelUrls();

    setSource(currentSource);
    setFunnel(currentFunnel);
  }, []);

  function diffFunc(displayed: any[]) {
    const displayed_first_elem = displayed[0].count;
    const displayed_last_elem = displayed[displayed.length - 1].count;

    setDiff(
      Math.trunc((displayed_last_elem / displayed_first_elem) * 1000) / 10
    );
  }

  let audio = new Audio("/no_cookiee_no_cry.mp3");
  const start = () => {
    audio.play();
  };

  let audioNomNom = new Audio("/Om nom nom nom.mp3");
  const startNomNom = () => {
    audioNomNom.play();
  };

  async function deleteIt() {
    const res = await deleteAllInDomain(domain);
  }

  const deleteAll = () => {
    if (
      window.confirm(
        "OBS! \nAre you sure you want to DELETE all funnels, sources and events for: " +
          JSON.stringify(domain)
      ) == true
    ) {
      //SLETT DATA HER
      deleteIt();
      startNomNom();
      window.confirm("oh shit! It got deleted :O");
    }
  };

  return (
    <div className="Bar__funnelContainer">

      <h5>Create a funnel to filter urls <br />  Create source-tags to compare activity from different sources</h5>

      <div className="Bar__convAndDateContainer">
        <div
          className="Bar__convContainer"
          style={{
            visibility: `${
              currentFunnel === "All sites" ? "hidden" : "visible"
            }`,
          }}
        >
          Conversion: {diff}%
        </div>

        <div className="Bar__dateContainer">
          <div className="Bar__date">01.01.2022 - 07.01.2022</div>
          <button className="buttonDiv" onClick={start}>
            <img src={dateIcon} alt="" className="Bar__dateIcon" />
            <div className="Bar__dateSpan">7d</div>
          </button>
        </div>
      </div>

      <div className="Bar__sourceInfo infoText">
        Non-unique page loads from source: {currentSource}
      </div>

      {urlList.map((page, index) => {
        return (
          <div key={index} className="Bar__barContainer">
            <div className="Bar__stepTitle"> {page.url} </div>

            <div
              className="Bar__bar__foreground"
              style={{ width: `${(page.count * 100) / largest}%` }}
            ></div>
            <div
              className="Bar__bar__background"
              style={{
                width: `${100 - (page.count * 100) / largest}%`,
              }}
            >
              <div className="Bar__views"> {page.count} </div>
            </div>
          </div>
        );
      })}

      <button className="buttonDiv Tag__button Bar__editButton">
        <img src={penIcon} alt="" className="buttonIcon" />
        <div className="buttonText">Edit funnel</div>
      </button>

      <div className="Bar__deleteContainer">
        <button className="Bar__deleteButton" onClick={() => deleteAll()}>
          <div className="Bar__deleteText">Clear all data for {domain}</div>
        </button>
      </div>
    </div>
  );
};

export default BarChart;

import { useState } from "react";
import { createContainer } from "unstated-next";

const STORAGE_KEY = "CHOSEN_DOMAIN";

function useDomain() {
  const [domain, setDomain] = useState(localStorage.getItem(STORAGE_KEY) || "");

  const changeDomain = (newDomain: string) => {
    localStorage.setItem(STORAGE_KEY, newDomain);
    setDomain(newDomain);
  };
  return { domain, changeDomain };
}

const DomainContainer = createContainer(useDomain);
export const DomainProvider = DomainContainer.Provider;
export const useDomainStore = () => DomainContainer.useContainer();

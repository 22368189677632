import { RouteComponentProps } from "@reach/router";
import { FC, useEffect, useState } from "react";
import BarChart from "./BarChart";
import TagTable from "./TagTable";
import FunnelTable from "./FunnelTable";

import NavHeader from "./NavHeader";

import { getAllSources, getAllFunnels } from "./PassToAPI";
import { useDomainStore } from "./store/domain";

import "./Analyse.css";
import "./Common.css";

export interface IadID {
  webpage: string;
  pageLoads: number;
  tag: string;
}

const Graph: FC<RouteComponentProps> = () => {
  const [currentView, setCurrentView] = useState("total");

  const [currentFunnel, setCurrentFunnel] = useState("All sites");
  const [funnelList, setFunnels] = useState<string[]>([""]);
  const [sourceList, setSources] = useState<string[]>([]);

  const { domain, changeDomain } = useDomainStore();

  useEffect(() => {
    //waitingFunctionTagg(AdTagList, setFunctions);
    //placeholder until choose website is there
    getSources();
    getFunnels();
  }, []);

  //get sources and send to TagTable
  async function getSources() {
    const result = await getAllSources(domain);
    let array: string[];
    array = [];
    for (let r of result) {
      array.push(r.source);
    }

    array.push("total");
    console.log(array);
    setSources(array);
    setCurrentView(array[0]);
  }

  async function getFunnels() {
    //add get funnels func here
    const result = await getAllFunnels(domain);
    let array: string[];
    array = [];
    array.push("All sites");
    for (let r of result) {
      array.push(r.name);
    }
    setFunnels(array);
    setCurrentFunnel(array[0]);
  }

  return (
    <div>
      <NavHeader></NavHeader>

      <FunnelTable funnels={funnelList} dataChanger={setCurrentFunnel} />

      <div className="Graph__container">
        <TagTable tags={sourceList} dataChanger={setCurrentView} />
        <BarChart currentSource={currentView} currentFunnel={currentFunnel} />
      </div>
    </div>
  );
};

export default Graph;

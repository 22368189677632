import { Router } from "@reach/router";
import { FC } from "react";
import "./Analytics.css";
import "./Common.css";
import Graph from "./Graph";
import Home from "./Home";
import Funnel from "./Funnel";
import Sources from "./Sources";
import Info from "./Info";
import AddWebsite from "./AddWebsite";
import AddWebsiteSuccess from "./AddWebsiteSuccess";
// import { Tracker } from "./Tracker/Tracker";
import { DomainProvider } from "./store/domain";
import Delete from "./Delete";

// Tracker();

export const App: FC = () => {
  console.log("Rendering app");
  return (
    <DomainProvider>
      <div>
        <Router>
          <Graph path="/graph" />
          <Home path="/" />
          <Funnel path="/funnel" />
          <Sources path="/sources" />
          <AddWebsite path="/add_website" />
          <AddWebsiteSuccess path="/add_website_success" />
          <Info path="/info" />

          <Delete path="/delete" />
        </Router>
      </div>
    </DomainProvider>
  );
};

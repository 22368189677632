import { useState } from "react";
import "./Analyse.css";
import "./Common.css";
import penIcon from "./penIcon.svg";
import { Link } from "@reach/router";
import { useDomainStore } from "./store/domain";

export interface tagsProps {
  tags: string[];
  dataChanger: (val: string) => void;
}

const TagTable = ({ tags, dataChanger }: tagsProps) => {
  const [isClicked, setIsClicked] = useState(tags[0]);

  const handleClick = (tag: string) => {
    dataChanger(tag);
    console.log(tag);
    setIsClicked(tag);
    // setIsClicked(!isClicked)
    // tag.classList.add('Tag__buttonSelected')

    return tag;
  };

  return (
    <div className="Tag__container">
      {tags.map((tag, index) => (
        <button
          key={tag}
          //className="tabButton Tag__button"
          //className="Tag__buttonSelected"
          className={
            isClicked === tag ? "Tag__buttonSelected" : "tabButton Tag__button"
          }
          onClick={() => handleClick(tag)}
        >
          {tag}
        </button>
      ))}

      <Link to={"/sources"}>
        <div className="buttonDiv Tag__manageSources">
          <img src={penIcon} alt="" className="buttonIcon" />
          <div className="buttonText">Manage sources</div>
        </div>
      </Link>
    </div>
  );
};

export default TagTable;

import { RouteComponentProps, Link } from "@reach/router";
import { FC, useState, useEffect } from "react";
import "./Sources.css";
import "./Common.css";
import bossBigIcon from "./bossBigIcon.svg";
import plussIcon from "./plussIcon.svg";
import exitIcon from "./exitIcon.svg";
import copyIcon from "./copyIcon.svg";
import { getAllSources, postSource } from "./PassToAPI";
import { useDomainStore } from "./store/domain";

function getURLComment(source: string): string {
  if (source === "") {
    return "";
  }
  return "/?utm_source=" + source;
}

const Sources: FC<RouteComponentProps> = () => {
  const [sourceList, setSourceList] = useState<string[]>([]);
  const [oldSourceList, setOldSourceList] = useState<string[]>([]);
  const { domain, changeDomain } = useDomainStore();

  const handleInputChange = (e: any, index: number) => {
    const { name, value } = e.target;
    const list: string[] = [...sourceList];
    console.log("list index name ", list[index]);
    console.log("value", value);
    list[index] = value;
    setSourceList(list);
    console.log("list index name ", list);
  };

  async function getSources() {
    let sources = await getAllSources(domain);
    let array: string[] = [];
    console.log("sources", sources);
    sources.forEach((element: { source: string }) => {
      array.push(element.source);
    });
    array.push("");
    setSourceList(array);
    setOldSourceList(array);
    console.log("sourcelist", array);
  }

  async function sendSource(domain: string, source: string) {
    let result = await postSource(domain, source);
    return result;
  }

  function handleSubmit() {
    console.log("Source list in submit button", sourceList);
    console.log("Old Source list in submit button", oldSourceList);
    sourceList.forEach((source) => {
      console.log("source: ", source);
      if (!oldSourceList.includes(source)) {
        //Only update if the spurce is not there from before
        let result = sendSource(domain, source);
        console.log("posting sources: ", source, result);
      }
    });
  }

  useEffect(() => {
    getSources();
  }, []);

  return (
    <div>
      <div className="container">
        {/* EXIT (må fikse tilbakelink (også i Funnel.tsx)) */}
        <div className="exit zoom">
          <Link to={"/graph"}>
            <img src={exitIcon} alt="" />
          </Link>
        </div>

        {/* TITLE */}
        <div className="titleSources">Manage sources</div>

        <h5>
          Place the tag at the end of your url to track activity from that
          source. <br />The tag will follow vistors through your website. <br />(example.com/?utm_source=Ad1 -&gt; example.com/path/?utm_source=Ad1)
        </h5>

        {/* Sourcesss */}
        <form action="">
          <div>
            {sourceList.map((source, i) => {
              return (
                <div>
                  <div className="sourcesContainer">
                    <div
                      className="sourceContainer"
                      style={{
                        display: source === "none" ? "none" : "flex",
                      }}
                    >
                      <div className="inputContainer">
                        <div className="flexDemUp">
                          <div className="nameContainer">
                            <div className="infoText">Source:</div>
                            <input
                              className="inpt name"
                              type="text"
                              value={source}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </div>
                          <div className="urlInContainer">
                            <div className="copyContainer">
                              <div className="blueBox"></div>
                              <input
                                className="urlOut"
                                type="text"
                                onChange={() => {}}
                                value={getURLComment(source)}
                              />
                              <button className="transpBack copyButton zoom">
                                <img src={copyIcon} alt="" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <button className="transpBack removeButton zoom">
                        <img src={bossBigIcon} alt="" />
                      </button>
                    </div>
                  </div>
                  <div>
                    {sourceList.length - 1 === i && (
                      <button
                        className="newSourceButton transpBack"
                        onClick={() => setSourceList([...sourceList, ""])}
                      >
                        <div className="buttonDiv">
                          <img src={plussIcon} alt="" className="buttonIcon" />
                          <div className="buttonText">New source</div>
                        </div>
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          {/* SAVE AND CLOSE BUTTON */}
          <Link to={"/graph"}>
            <button
              className="transpBack submitPos"
              onClick={() => handleSubmit()}
            >
              <div className="submit">Save and close</div>
            </button>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Sources;

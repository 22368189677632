// OBS OBS! Endringer her må gjentas i AddWebsiteSucess.tsx

import { RouteComponentProps, Link } from "@reach/router";
import { FC } from "react";
import "./AddWebsite.css";
import "./Common.css";
import "./Sources.css";
import { postDomain } from "./PassToAPI";

import ImplementCode from "./ImplementCode";
import NavHeader from "./NavHeader";
import { useState } from "react";

const AddWebsite: FC<RouteComponentProps> = () => {
  const [domain, setDomain] = useState<string>("");

  async function handleSubmit(domain: string) {
    const result = await postDomain(domain);
    console.log(result);
    window.location.href = "/add_website_success";
  }

  return (
    <div>
      <NavHeader />
      <ImplementCode />

      <div className="addWebContainer">
        <h3>Add website</h3>

        <h4>Add your website to our server: </h4>
        <div className="infoText">Exclude "https://"" and the last "/"</div>

        <div className="flexDemUp">
          <div className="webInputContainer">
            <input
              className="webInput"
              type="text"
              placeholder="example.com"
              onChange={(event) => setDomain(event.target.value)}
            />
          </div>
          <button className="transpBack" onClick={() => handleSubmit(domain)}>
            <div className="buttonDiv confirmButton">Confirm</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddWebsite;

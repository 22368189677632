
import "./AddWebsite.css";
import "./Common.css";
import copyIcon from "./copyIcon.svg"









const ImplementCode = () => {
    return(
        <div className="addWebContainer">
          <h3>Implement code</h3>
          <h4>Insert this script in the body of your website:</h4>

          <div className="copyContainer">
            <div className="blueBox"></div>
            <input className="urlOut" type="text" value='<script src="https://analytics-frontend.test.iterate.no/script.js"></script>'/>
            <button className="transpBack copyButton zoom">
              <img src={copyIcon} alt="" />
            </button>
          </div>

          <h5>
            Our code is <a href="https://google.com" className="link">open source!</a>  
            <br />
            Learn more about how it works <a href="https://google.com" className="link">here!</a> 
          </h5>
        </div>

    );
};
export default ImplementCode;